import { type AlignKeyType } from '@/styles/display'
import spaceStyle, { type SpacingProps, buildSpaceStyle } from '@/styles/space'
import { type V1AppColorTheme } from 'my-recruiter-app-ts-if'
import { type HTMLAttributes } from 'react'
import styled from 'styled-components'

interface RowProps extends HTMLAttributes<HTMLDivElement> {
  gutter?: number
  children?: React.ReactNode
  justify?: AlignKeyType
  align?: AlignKeyType
  backgroundColor?: V1AppColorTheme
  padding?: SpacingProps
  margin?: SpacingProps
  height?: string
  flexWrap?: 'wrap' | 'nowrap'
}

const StyledRow = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${(props) => props.flexWrap ?? 'wrap'};
  gap: ${(props) => props.gutter ?? 0}px;
  justify-content: ${(props) => props.justify ?? 'unset'};
  align-items: ${(props) => props.align ?? 'stretch'};
  background-color: ${(props) =>
    props.backgroundColor?.color !== undefined &&
    props.backgroundColor?.color}};
  padding: ${(props) => {
    return props.padding !== undefined
      ? buildSpaceStyle(props.padding)
      : spaceStyle.NONE
  }};
  margin: ${(props) => {
    return props.margin !== undefined
      ? buildSpaceStyle(props.margin)
      : spaceStyle.NONE
  }};
  height: ${(props) => props.height !== undefined && props.height};
`

const Row: React.FC<RowProps> = (props: RowProps) => {
  return <StyledRow {...props}>{props.children}</StyledRow>
}

export default Row
